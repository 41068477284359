<template>
<div>
<v-row class="justify-center" v-if="loading" align="center">
   <v-skeleton-loader
      class="mx-auto"
      width="50%"
      type="card"
    ></v-skeleton-loader>
 <!-- <v-progress-circular  
      :size="50"
      color="primary"
      indeterminate
      class="mb-10"
    ></v-progress-circular> -->
</v-row>
    <highcharts v-else :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  props: {
      chart: { default: null, type: Object },
      loading: { default:null, type:Boolean},
      type: { default: "bar", type: String},
      height: {default: null, type:Number}
    },  
  components: {
    highcharts: Chart
  },
  data: () => ({
  }),
  computed: {
    chartOptions() {
      return {
        title: {
          text: this.chart ? this.chart.title : "",
        },
        chart: {
          type: this.type,
          backgroundColor: this.chart.backgroundColor,
          height: this.height 
        },
        xAxis: { categories: this.chart ? this.chart.categories : [] },
        series: this.chart.series
      };
    }
  }
};
</script>