import Vue from "vue";

export default new (class SegmentService {

  async GetMerchantById(id) {
    const path = `/api/merchants/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetMerchants() {
    const path = `/api/merchants/merchants`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetMerchantTypes() {
    const path = `/api/merchants/MerchantTypes`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetMerchantsFeatureCollection(merchants ) {
    const path = "/api/merchants/GetMerchantsFeatureCollection";
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: merchants,
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  } 

  async CreateDistributionPlan(name, merchants, minutes, mode, priority){
    const path = `/api/merchants/CreateDistributionPlan/${name}/${mode}/${minutes}/${priority}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: merchants
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }   
  
  async DistributionReports() {
    const path = `/api/merchants/DistributionReports`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }   
  
  
  async MerchantPostalcodeDistributionChart(id) {
    const path = `/api/merchants/MerchantPostalcodeDistributionChart/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }  

  async MerchantHouseHoldDistributionChart(id) {
    const path = `/api/merchants/MerchantHouseHoldDistributionChart/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }   
  
  async DownloadDistributionPlan(id) {
    const path = `/api/merchants/DownloadDistributionPlan/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get",
      responseType: "blob"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

      
    return result;
  }   
  
  
  async GetPricingReport(data){
    const path = `/api/merchants/PricingReport`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: data
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }     

  // async GetMerchantsFeatureCollection(merchants){
  //   const path = `/api/merchants/GetMerchantsFeatureCollection`;
  //   let result = null;
  //   await Vue.prototype.$axios.get(path, {
  //     params: {
  //       merchants: merchants
  //     }
  //   })
  //     .then((response) => {
  //       result = response.data;
  //     })
  //     .catch((error) => {
  //       result = error.response.data
  //     });

  //   return result;
  // }  
  
})();
