<template>
<div>
  <v-sheet color="grey lighten-3" min-height="10vh">
    <v-container fill-height>
      <v-row dense justify="start" >
    <div class="transition-swing text-h4 mt-10 mb-2" >
    Distribution Report
    </div>
      </v-row>
      <v-row dense justify="start" >
        <v-col lg="6" md="6" cols="12">
        <v-autocomplete
        v-model="reportId"
        :items="reports"
        item-text="name"
        item-value="id"
        label="Report"
        @input="loadCharts"
        >

        </v-autocomplete>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
  <v-sheet elevation="5" color="grey lighten-5">
    <div v-if="selectedReportObject">
          <v-container>
       <v-row justify="left" dense>
         <v-card elevation="3" class="mx-2">
           <v-card-title>
              {{priorityModeText}}
           </v-card-title>           
           <v-card-subtitle>
              Allotment method
           </v-card-subtitle>
         </v-card>     
       </v-row>
          </v-container>
    </div>
    </v-sheet>
    <div v-if="!reportId">
  <v-sheet color="grey lighten-4">
    <v-container>
       <v-row justify="start" dense>
          <v-col lg="6" cols="12">
            <v-alert type="warning">
            No report selected
            </v-alert>
          </v-col>
       </v-row>
    </v-container>
  </v-sheet>
    </div>
  <div v-if="reportId">
  <v-sheet color="grey lighten-4">
    <v-container>
        <v-row justify="center" dense>
          <v-col lg="6" cols="12">
            <v-card color="rgba(0,0,0,0)">
            <barchart :chart="barChartMerchantPostalcodes" :loading="!barChartMerchantPostalcodes"/>
            </v-card>
          </v-col>
       <v-col lg="6" cols="12">
           <v-card color="rgba(0,0,0,0)">
            <barchart :chart="barChartHouseHoldPostalcodes" :loading="!barChartHouseHoldPostalcodes"/>
           </v-card>
          </v-col>          
        </v-row>
    </v-container>
  </v-sheet>
  <v-sheet color="grey lighten-2" height="40vh"  elevation="2">
    <v-container>
      <v-row > 
        <v-col>
          <v-btn color="primary" small @click="download" :loading="loadingDownload">
            Get Report
            <v-icon small>mdi-download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
  </div>
</div>
</template>

<script>
import merchantService from "@/services/MerchantService";
import barchart from "@/components/charts/HighCharts/BarChart"

export default {
  components: {
    barchart
  },
  data: () => ({
    reports: [],
    reportId: null,
    loading: false,
    loadingDownload: false,
    showResult: false,
    succeeded: true,
    barChartMerchantPostalcodes: null,
    barChartHouseHoldPostalcodes: null,
    export: null,
    errors: []
  }),
  computed: {
    merchants() {
      return this.$store.getters["filter/filterRequest"].merchants;
    },
    selectedReportObject(){
      return this.reports.find(i => i.id == this.reportId)
    },
    priorityModeText(){
      const id = this.selectedReportObject.allottmentPriority
      let text = "";

      switch (id) {
        case 1:
          text = 'Sales desc.' 
          break;
      case 2:
           text = 'Sales asc.'
           break;
      case 3:
        text = 'Air distance'
        break;
      }

          return text;

    }
  },
  async mounted() {
      this.reportId = this.$route.query.reportId
      if(this.reportId != null)
        await this.loadCharts()

      await this.getReports()
  },
  methods: {
    async getReports(){
      this.reports = await merchantService.DistributionReports();
    },
    async loadCharts() {
      this.showResult = false
      this.loading = true;

      this.barChartMerchantPostalcodes = await merchantService.MerchantPostalcodeDistributionChart(this.reportId);
      this.barChartHouseHoldPostalcodes = await merchantService.MerchantHouseHoldDistributionChart(this.reportId);
        this.loading = false
    },
    async download(){
      this.loadingDownload = true
      let report = await merchantService.DownloadDistributionPlan(this.reportId);

      const url_1 = window.URL.createObjectURL(new Blob([report]));
      const link = document.createElement("a");
      link.href = url_1;
      var reportName = this.selectedReportObject.name
      var filedate = new Date()
        .toISOString()
        .slice(-24)
        .replace(/\D/g, "")
        .slice(0, 14);
      link.setAttribute("download", `${reportName}${filedate}` + ".csv");
      document.body.appendChild(link);
      link.click();
      

      this.loadingDownload = false

    }
  }
};
</script>